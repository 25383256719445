import React, { useState } from 'react';
import './App.css';
import axios from 'axios';
import config from './config.dev';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import index from "./index";       // Your existing page component
import Reports from "./Reports"; // Your new Reports page component

function MyForm() {
  // Define the overall form state including caregiver info
  const [formData, setFormData] = useState({
    familyNo: '',
    prisoner: '',
    caregiver: '',
    phoneNumber: '',
    address: '', // New field for Address
    caller: '', //field for person completing the form
    childrenData: [],
  });

  const orderedData = {
    familyNo: formData.familyNo,
    prisoner: formData.prisoner,
    caregiver: formData.caregiver,
    phoneNumber: formData.phoneNumber,
    address: formData.address,
    caller: formData.caller,
    childrenData: formData.childrenData.map(child => ({
      familyNo: formData.familyNo,
      name: child.name,
      subletter: child.subletter,
      age: child.age,
      gender: child.gender,
      fungift: child.fungift,
      clothinggift: child.clothinggift,
      clothingsize: child.clothingsize,
      clothingcategory: child.clothingcategory,
    }))
  };

  const [childrenCount, setChildrenCount] = useState(0);
  const [errors, setErrors] = useState({});

  const apiEndpoint = config.apiBaseUrl + '/api/family/create';
  const date = "2024-11-08";

  // Handle changes for static form fields (prisoner name, caregiver info, etc.)
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle changes in the number of children
  const handleChildrenCountChange = (e) => {
    const count = parseInt(e.target.value, 10);

    if (isNaN(count)) {
      // If the count is not a valid number (e.g., an empty value), reset children data
      setChildrenCount(0);
      setFormData({ ...formData, childrenData: [] });
      return;
    }

    setChildrenCount(count);

    // Safely resize the childrenData array
    const updatedChildrenData = [...formData.childrenData];

    if (count > updatedChildrenData.length) {
      // If increasing the number of children, add new empty child objects
      for (let i = updatedChildrenData.length; i < count; i++) {
        updatedChildrenData.push({
          familyNo:'',
          name: '',
          subletter: String.fromCharCode(65 + i), // Add subletter here
          gender:'',
          age: '',
          fungift: '',
          clothinggift: '',
          clothingsize: '',
          clothingcategory: '',
        });
      }
    } else {
      // If reducing the number of children, slice the array to the new size
      updatedChildrenData.length = count;
    }

    setFormData({ ...formData, childrenData: updatedChildrenData });
  };

  // Handle input changes for individual children
  const handleChildDataChange = (e, index) => {
    const { name, value } = e.target;
    const updatedChildrenData = [...formData.childrenData];
    updatedChildrenData[index] = {
      ...updatedChildrenData[index],
      [name]: value,
    };
    setFormData({ ...formData, childrenData: updatedChildrenData });
  };
  const handleFilteredPrint = async (e) => {
       e.preventDefault();
       try {
           console.log("Date being sent:", date);
    const response = await axios.post(`https:\/\/crossroadsangeltree.com\/api\/family\/pfilteredEmailGiftList?tagDate=${date}`, {
        _t: new Date().getTime()
    });
           if (response.status === 201) {
               alert(response.data);
           } else {
               alert("An unexpected response was received.");
           }
       } catch (error) {
           if (error.response) {
               alert(error.response.data);
           } else {
               alert("Failed to connect to the server.");
           }
       } finally {

       }
   };

 // Handle form submission
 const handleSubmit = (e) => {
     e.preventDefault(); // Prevent page refresh

     // Perform validation before proceeding with the API request
     if (isValid(formData)) {
         console.log('Form data is valid, proceeding with API call...');
         console.log('Ordered Data:', JSON.stringify(orderedData, null, 2));

         // Example: Sending orderedData via Axios
         axios.post('https://crossroadsangeltree.com/api/family/create', orderedData)
             .then(response => {
                 if (response.status === 201) {
                     // If family created successfully (HTTP 201)
                     console.log('Family created successfully:', response.data);

                     // Flash a success message (you can update state to display this in your UI)
                     alert('Family created successfully!');

                     // Reset form to initial state after successful submission
                     setFormData({
                         familyNo: '',
                         prisoner: '',
                         caregiver: '',
                         phoneNumber: '',
                         address: '',
                         caller: '',
                         childrenData: [],
                     });
                     // Reset the number of children (childrenCount)
                         setChildrenCount(0);  // Reset children count dropdown
                 }
             })
             .catch(error => {
                 if (error.response && error.response.status === 409) {
                     // If a conflict error occurred (HTTP 409)
                     console.error('Duplicate Family Number:', error.response.data);

                     // Flash an error message (use state to display this in your UI)
                     alert('Duplicate Family Number, please correct and re-enter.');
                 } else {
                     // Handle other potential errors
                     console.error('Error saving data:', error);
                     alert('An error occurred while saving the data.');
                 }
             });
     } else {
         console.log('Form data is invalid.');
         alert('Form data is invalid. Please correct the errors and try again.');
     }
 };

//  validation function
const isValid = (data) => {
  let newErrors = {};
  let childrenErrors = [];

  // Validate familyNo (1 or 2 alphabetic characters)
  if (!/^[A-Za-z]{1,2}$/.test(data.familyNo)) {
    newErrors.familyNo = "Family number must be one or two alphabetic characters.";
    console.log("Invalid familyNo:", data.familyNo);
  }

  // Validate prisoner
  if (data.prisoner === '') {
    newErrors.prisoner = "Prisoner name is required.";
    console.log("Invalid prisoner:", data.prisoner);
  }

  // Validate caregiver name
  if (data.caregivername === '') {
    newErrors.caregivername = "Caregiver name is required.";
    console.log("Invalid caregivername:", data.caregivername);
  }

  // Validate phone number
  if (data.phoneNumber === '') {
    newErrors.phoneNumber = "Caregiver phone number is required.";
    console.log("Invalid phoneNumber:", data.phoneNumber);
  }

  // Validate address
  if (data.address === '') {
    newErrors.address = "Caregiver address is required.";
    console.log("Invalid address:", data.address);
  }

  // Validate children data
  if (!data.childrenData || data.childrenData.length < 1) {
    newErrors.children = "There must be at least one child.";
    console.log("No children data provided.");
  } else {
    data.childrenData.forEach((child, index) => {
      let childErrors = {};

      if (!child.name) {
        childErrors.name = `Child ${child.subletter} is missing a name.`;
        console.log(`Invalid child name for child ${child.subletter}`);
      }
      if (!child.age) {
        childErrors.age = `Child ${child.subletter} is missing an age.`;
        console.log(`Invalid child age for child ${child.subletter}`);
      }
      if (!child.gender) {
        childErrors.gender = `Child ${child.subletter} is missing a gender.`;
        console.log(`Invalid child gender for child ${child.subletter}`);
      }
      if (!child.fungift) {
        childErrors.fungift = `Child ${child.subletter} is missing a toy/fun gift.`;
        console.log(`Invalid child fungift for child ${child.subletter}`);
      }
      if (!child.clothinggift) {
        childErrors.clothinggift = `Child ${child.subletter} is missing a clothing gift.`;
        console.log(`Invalid child clothinggift for child ${child.subletter}`);
      }
      if (!child.clothingsize) {
        childErrors.clothingsize = `Child ${child.subletter} is missing a clothing size.`;
        console.log(`Invalid child clothingsize for child ${child.subletter}`);
      }
      if (!child.clothingcategory) {
        childErrors.clothingcategory = `Child ${child.subletter} is missing a clothing category.`;
        console.log(`Invalid child clothingcategory for child ${child.subletter}`);
      }

      if (Object.keys(childErrors).length > 0) {
        childrenErrors[index] = childErrors;
      }
    });
  }

  // Collect all errors
  if (childrenErrors.length > 0) {
    newErrors.children = childrenErrors;
  }

  // Check if there are any errors
  if (Object.keys(newErrors).length > 0) {
    console.log("Validation errors:", newErrors);
    setErrors(newErrors);
    return false;
  }

  // No errors, form is valid
  setErrors({});
  return true;
};
  // Render child forms dynamically
  const renderChildForms = () => {
    const rows = [];

    for (let i = 0; i < childrenCount; i += 2) {
      rows.push(
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',  // Ensure both children are vertically aligned
            marginBottom: '8px', // Add some space between rows
          }}
          key={i}
        >
         {/* Left Child */}
         <div style={{ width: '58%', textAlign: 'left', padding: '7%' }}>
           <h3>Child {String.fromCharCode(65 + i)}</h3>
           {/* Adding subletter */}
           <input
             type="hidden"
             name={`child${i}subletter`}
             value={String.fromCharCode(65 + i)}
           />
            <input
              type="hidden"
              name={`child${i + 1}familyNo`}
              value={formData.familyNo || ''} // Ensures the value is set to empty string if familyNo is not yet populated
            />

           <label htmlFor={`child${i}name`}>Name:</label><br />
           <input
             name="name"
             size="24"
             type="text"
             value={formData.childrenData[i]?.name || ''}
             onChange={(e) => handleChildDataChange(e, i)}
           /><br />
           {/* Proper rendering of error messages */}
           {errors.children && errors.children[i]?.name && (
             <span style={{ color: 'red' }}>{errors.children[i].name}</span>
           )}
          <br />

           <label htmlFor={`child${i}age`}>age:</label><br />
           <input
             name="age"
             size="24"
             type="text"
             value={formData.childrenData[i]?.age || ''}
             onChange={(e) => handleChildDataChange(e, i)}
           /><br />
            {/* Proper rendering of error messages */}
                      {errors.children && errors.children[i]?.age && (
                        <span style={{ color: 'red' }}>{errors.children[i].age}</span>
                      )}
                     <br />

           <label htmlFor={`child${i}Gender`}>Gender:</label><br />
           <select
             name="gender"
             value={formData.childrenData[i]?.gender || ''}
             onChange={(e) => handleChildDataChange(e, i)}
           >
             <option value="">Select Gender</option>
             <option value="Boy">Boy</option>
             <option value="Girl">Girl</option>
           </select><br />
            {/* Proper rendering of error messages */}
                                 {errors.children && errors.children[i]?.gender && (
                                   <span style={{ color: 'red' }}>{errors.children[i].gender}</span>
                                 )}
                                <br />

           <label htmlFor={`fungift${i}`}>Toy/Fun Gift Requested:</label><br />
           <input
             name="fungift"
             size="34"
             type="text"
             value={formData.childrenData[i]?.fungift || ''}
             onChange={(e) => handleChildDataChange(e, i)}
           /><br />
            {/* Proper rendering of error messages */}
                                 {errors.children && errors.children[i]?.fungift && (
                                   <span style={{ color: 'red' }}>{errors.children[i].fungift}</span>
                                 )}
                                <br />

           <label htmlFor={`clothinggift${i}`}>Clothing Gift Requested:</label><br />
           <input
             name="clothinggift"
             size="34"
             type="text"
             value={formData.childrenData[i]?.clothinggift || ''}
             onChange={(e) => handleChildDataChange(e, i)}
           /><br />
            {/* Proper rendering of error messages */}
                                 {errors.children && errors.children[i]?.clothinggift && (
                                   <span style={{ color: 'red' }}>{errors.children[i].clothinggift}</span>
                                 )}
                                <br />

           <label htmlFor={`clothingsize${i}`}>Size:</label><br />
           <input
             name="clothingsize"
             size="24"
             type="text"
             value={formData.childrenData[i]?.clothingsize || ''}
             onChange={(e) => handleChildDataChange(e, i)}
           /><br /> {/* Proper rendering of error messages */}
                                         {errors.children && errors.children[i]?.clothingsize && (
                                           <span style={{ color: 'red' }}>{errors.children[i].clothingsize}</span>
                                         )}
                                        <br />

           <label htmlFor={`clothingcategory${i}`}>Clothing Category:</label><br />
           <select
             name="clothingcategory"
             value={formData.childrenData[i]?.clothingcategory || ''}
             onChange={(e) => handleChildDataChange(e, i)}
           >
             <option value="">Size category</option>
             <option value="child">Child</option>
             <option value="junior">Junior</option>
             <option value="adult">Adult</option>
           </select><br />
            {/* Proper rendering of error messages */}
                                 {errors.children && errors.children[i]?.clothingcategory && (
                                   <span style={{ color: 'red' }}>{errors.children[i].clothingcategory}</span>
                                 )}
                                <br />
         </div>

         {/* Right Child, if exists */}
         {i + 1 < childrenCount && (
           <div style={{ width: '36%', textAlign: 'left', padding: '2%' }}>
              <h3>Child {String.fromCharCode(65 + i+1)}</h3>
                        {/* Adding subletter */}
                        <input
                          type="hidden"
                          name={`child${i+1}subletter`}
                          value={String.fromCharCode(65 + i+1)}
                        />

                        <input
                          type="hidden"
                          name={`child${i + 1}familyNo`}
                          value={formData.familyNo || ''} // Ensures the value is set to empty string if familyNo is not yet populated
                        />


                        <label htmlFor={`child${i+1}name`}>Name:</label><br />
                        <input
                          name="name"
                          size="24"
                          type="text"
                          value={formData.childrenData[i+1]?.name || ''}
                          onChange={(e) => handleChildDataChange(e, i+1)}
                        /><br />
                        {/* Proper rendering of error messages */}
                        {errors.children && errors.children[i+1]?.name && (
                          <span style={{ color: 'red' }}>{errors.children[i+1].name}</span>
                        )}
                       <br />

                        <label htmlFor={`child${i+1}age`}>age:</label><br />
                        <input
                          name="age"
                          size="24"
                          type="text"
                          value={formData.childrenData[i+1]?.age || ''}
                          onChange={(e) => handleChildDataChange(e, i+1)}
                        /><br />
                         {/* Proper rendering of error messages */}
                                   {errors.children && errors.children[i+1]?.age && (
                                     <span style={{ color: 'red' }}>{errors.children[i+1].age}</span>
                                   )}
                                  <br />

                        <label htmlFor={`child${i+1}Gender`}>Gender:</label><br />
                        <select
                          name="gender"
                          value={formData.childrenData[i+1]?.gender || ''}
                          onChange={(e) => handleChildDataChange(e, i+1)}
                        >
                          <option value="">Select Gender</option>
                          <option value="Boy">Boy</option>
                          <option value="Girl">Girl</option>
                        </select><br />
                         {/* Proper rendering of error messages */}
                                              {errors.children && errors.children[i+1]?.gender && (
                                                <span style={{ color: 'red' }}>{errors.children[i+1].gender}</span>
                                              )}
                                             <br />

                        <label htmlFor={`fungift${i+1}`}>Toy/Fun Gift Requested:</label><br />
                        <input
                          name="fungift"
                          size="34"
                          type="text"
                          value={formData.childrenData[i+1]?.fungift || ''}
                          onChange={(e) => handleChildDataChange(e, i+1)}
                        /><br />
                         {/* Proper rendering of error messages */}
                                              {errors.children && errors.children[i+1]?.fungift && (
                                                <span style={{ color: 'red' }}>{errors.children[i+1].fungift}</span>
                                              )}
                                             <br />

                        <label htmlFor={`clothinggift${i+1}`}>Clothing Gift Requested:</label><br />
                        <input
                          name="clothinggift"
                          size="34"
                          type="text"
                          value={formData.childrenData[i+1]?.clothinggift || ''}
                          onChange={(e) => handleChildDataChange(e, i+1)}
                        /><br />
                         {/* Proper rendering of error messages */}
                                              {errors.children && errors.children[i+1]?.clothinggift && (
                                                <span style={{ color: 'red' }}>{errors.children[i+1].clothinggift}</span>
                                              )}
                                             <br />

                        <label htmlFor={`clothingsize${i+1}`}>Size:</label><br />
                        <input
                          name="clothingsize"
                          size="24"
                          type="text"
                          value={formData.childrenData[i+1]?.clothingsize || ''}
                          onChange={(e) => handleChildDataChange(e, i+1)}
                        /><br /> {/* Proper rendering of error messages */}
                                                      {errors.children && errors.children[i+1]?.clothingsize && (
                                                        <span style={{ color: 'red' }}>{errors.children[i+1].clothingsize}</span>
                                                      )}
                                                     <br />

                        <label htmlFor={`clothingcategory${i+1}`}>Clothing Category:</label><br />
                        <select
                          name="clothingcategory"
                          value={formData.childrenData[i+1]?.clothingcategory || ''}
                          onChange={(e) => handleChildDataChange(e, i+1)}
                        >
                          <option value="">Size category</option>
                          <option value="child">Child</option>
                          <option value="junior">Junior</option>
                          <option value="adult">Adult</option>
                        </select><br />
                         {/* Proper rendering of error messages */}
                                              {errors.children && errors.children[i+1]?.clothingcategory && (
                                                <span style={{ color: 'red' }}>{errors.children[i+1].clothingcategory}</span>
                                              )}
                                             <br />
           </div>
         )}
        </div>
      );
    }
    return rows;
  };

  return (
    <div style={{ backgroundColor: '#ccdbf9' }}>
     <h1>Crossroads Angel Tree Entry Form</h1>
      <form onSubmit={handleSubmit}>
        <input type="hidden" name="mail_options" value="FromAddr=dennis@dennisholden.com" />

        {/* Static Form Fields */}

       <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
         <table border="0" cellpadding="2" cellspacing="2" width="529">
           <tbody>
             <tr>
               <td width="56%">
                 <label htmlFor="familyNo">Family Number:</label><br />
                 <input
                   name="familyNo"
                   size="24"
                   tabindex="1"
                   type="text"
                   value={formData.familyNo}
                   onChange={handleChange}
                 />
               </td>

               <td>
                 <label htmlFor="prisoner">Prisoner Name:</label><br />
                 <input
                   name="prisoner"
                   size="24"
                   tabindex="2"
                   type="text"
                   value={formData.prisoner}
                   onChange={handleChange}
                 />
               </td>
             </tr>

             <tr>
               <td width="56%">
                 <label htmlFor="caregiver">Caregiver Name:</label><br />
                 <input
                   name="caregiver"
                   size="24"
                   tabindex="3"
                   type="text"
                   value={formData.caregiver}
                   onChange={handleChange}
                 />
               </td>

               <td>
                 <label htmlFor="phoneNumber">Phone Number:</label><br />
                 <input
                   name="phoneNumber"
                   size="24"
                   tabindex="4"
                   type="text"
                   value={formData.phoneNumber}
                   onChange={handleChange}
                 />
               </td>
             </tr>

             <tr>
               <td width="56%">
                 <label htmlFor="address">Address:</label><br />
                 <input
                   name="address"
                   size="24"
                   tabindex="5"
                   type="text"
                   value={formData.address}
                   onChange={handleChange}
                 />
               </td>

               <td width="56%">
                 <label htmlFor="caller">Completed By/Notes:</label><br />
                 <input
                   name="caller"
                   size="24"
                   tabindex="5"
                   type="text"
                   value={formData.caller}
                   onChange={handleChange}
                 />
               </td>
             </tr>

             <tr>
               <td width="38%">
                 <label htmlFor="familysize">Number of children:</label><br />
                 <select
                   name="familysize"
                   value={childrenCount} // Bind the selected value to the state
                   onChange={handleChildrenCountChange} // Call handleChange when the user selects a new value
                 >
                   <option value="">Select number of children</option>  {/* Default option */}
                   <option value="1">1</option>
                   <option value="2">2</option>
                   <option value="3">3</option>
                   <option value="4">4</option>
                   <option value="5">5</option>
                   <option value="6">6</option>
                   <option value="7">7</option>
                   <option value="8">8</option>
                 </select>
               </td>
             </tr>
           </tbody>
         </table>
       </div>

        {/* Dynamically Generated Child Forms */}
        {renderChildForms()}

        <td width="56%" style={{ textAlign: 'center' }}>
          <input
            name="submitbutton"
            type="submit"
            value="Submit"
            style={{ transform: 'scale(1.15)', padding: '10px 20px' }}  // Proper object style
          />
        </td>

      </form>
    </div>
  );
}

export default MyForm;