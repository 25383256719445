// App.js
import React from 'react';
import MyForm from './MyForm'; // Import the form component
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Reports from "./Reports";

function App() {
  return (
    <Router> {/* Wrap everything in Router */}
      <div className="App">



        <Routes> {/* Define routes */}
          <Route path="/" element={<MyForm />} /> {/* Home route */}
          <Route path="/reports" element={<Reports/>} /> {/* Reports route */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;