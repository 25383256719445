import React, { useState } from 'react';
import './App.css';
import axios from 'axios';
import config from './config.dev';

const apiEndpoint = config.apiBaseUrl + '/family/emailGiftList';
function Reports() {
    const [isDisabled, setIsDisabled] = useState(false);
    const [password, setPassword] = useState('');
    const [isVerified, setIsVerified] = useState(false);
    const [date, setDate] = useState('');

    const correctPassword = 'Admin2024';

    const handlePrint = async (e) => {
        e.preventDefault();
        setIsDisabled(true);

        try {
            const response =await axios.post(apiEndpoint);
            if (response.status < 300) {
                alert(response.data);
            } else {
                alert("An unexpected response was received.");
            }
        } catch (error) {
            if (error.response) {
                alert(error.response.data);
            } else {
                alert("Failed to connect to the server.");
            }
        } finally {
            setIsDisabled(false);
        }
    };

   const handleFilteredPrint = async (e) => {
       e.preventDefault();
       if (!isValidDate(date)) {
           alert("Invalid date. Please enter a valid date in YYYY-MM-DD format.");
           return;
       }
       setIsDisabled(true);

       try {
           console.log("Date being sent:", date);
  const response = await axios.post(`/api/family/filteredEmailGiftList?tagDate=${date}`, {
      _t: new Date().getTime()
  });
           if (response.status < 300) {
               alert(response.data);
           } else {
               alert("An unexpected response was received.");
           }
       } catch (error) {
           if (error.response) {
               alert(error.response.data);
           } else {
               alert("Failed to connect to the server.");
           }
       } finally {
           setIsDisabled(false);
       }
   };

   const handleVerifyPassword = () => {
       if (password === correctPassword) {
           setIsVerified(true);
           alert("Password verified successfully!");
       } else {
           alert("Incorrect password. Please try again.");
       }
   };

    function isValidDate(dateString) {
        const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
        if (!dateRegex.test(dateString)) {
            return false;
        }
        const [year, month, day] = dateString.split('-').map(Number);
        if (year > 2030 || month < 1 || month > 12 || day < 1 || day > 31) {
            return false;
        }
        const daysInMonth = new Date(year, month, 0).getDate();
        return day <= daysInMonth;
    }

    return (
        <div>
            <h1>Reports Page</h1>

            {!isVerified && (
                <div>
                    <input
                        type="password"
                        placeholder="Enter Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="large-input"
                    />
                    <button onClick={handleVerifyPassword} className="large-button">Verify Password</button>
                </div>
            )}

            <button onClick={handlePrint} disabled={!isVerified || isDisabled} className="large-button">
                Print All Families
            </button>

            <div className="date-input-container">
                <input
                    type="text"
                    placeholder="Enter Date (YYYY-MM-DD)"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    className="large-input"
                />
                <button onClick={handleFilteredPrint} disabled={!isVerified || isDisabled} className="large-button">
                    Print Families Entered After a Specified Date
                </button>
            </div>
        </div>
    );
}

export default Reports;